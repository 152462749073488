<template>
  <div tft-article-divider>
    <span><i /><i /><i /></span>
  </div>
</template>

<script>
export default {
  name: 'TftArticleDivider',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[tft-article-divider] { .mv(32); .tc; .lh(0); .crop;
  span { .rel; .ib; .ib-list; .lh(0);
    &:before,
    &:after { .cnt; .abs; .t(3); .wh(100, 1); .bgc(rgba(255, 255, 255, .5)); }
    &:before { .l(100%); .ml(12);}
    &:after { .r(100%); .mr(12); }
  }
  i { .ib; .vam; .wh(8, 8); .no-repeat('@{tft-intro}/icon_divider_dot.svg'); .o(.5); .crop;
    + i { .ml(13); }
  }
  @media (@tl-up) {
    .mv(52);
    span {
      &:before,
      &:after { .w(200); }
    }
  }
  @media (@ds-up) {
  }
}
</style>
