<template>
  <PSection tft-section-match>
    <div class="inner-holder">
      <PItem class="_title">
        <SectionTitle title="대회는 이렇게 진행됩니다." theme="blue-black" />
      </PItem>
      <div class="arena-guide">
        <PItem tag="article" class="_content">
          <h5>
            <span>참가 기준</span>
          </h5>
          <div class="group">
            <figure>
              <img src="/img/pages/tftWeeklyCup/intro/img_match_arena_guide.png" alt="">
            </figure>
            <p>
              각 주 차별 접수 마감일 24시 기준
              <span><b>마스터 티어 이상</b>의 유저 참가 가능</span>
            </p>
          </div>
        </PItem>
        <PItem tag="article" class="_content">
          <h5>
            <span>대회 진행</span>
          </h5>
          <div class="group">
            <div class="box">
              <div class="inner">
                <p>대회 체크인 유저 중
                  <span><b>랭킹 상위 256명</b>을 선발하여 대회 진행</span>
                </p>
              </div>
            </div>
            <p><b>*체크인이란?</b> 대회 당일 12시 ~ 12시 50분까지 참가 의사를 최종 체크하는 시스템</p>
          </div>
        </PItem>
      </div>

      <ArticleDivider />

      <article class="arena-step">
        <PItem class="_title-sub">
          <SectionTitleSub title="진행 과정" theme="green" />
        </PItem>
        <PItem class="box2 content _content">
          <ul>
            <li>
              <h5>접수 시작</h5>
              <div class="group">
                <p>1/17 (월) 11:00~</p>
                <strong> 1 ~ 5주차</strong> <strong>접수 시작</strong>
              </div>
            </li>
            <li>
              <h5>접수 마감</h5>
              <div class="group">
                <ul>
                  <li><b>1주차</b> ~ 1/27(목) 18:00</li>
                  <li><b>2주차</b> ~ 2/17(목) 18:00</li>
                  <li><b>3주차</b> ~ 2/24(목) 18:00</li>
                  <li><b>4주차</b> ~ 3/10(목) 18:00</li>
                  <li><b>5주차</b> ~ 3/17(목) 18:00</li>
                </ul>
              </div>

            </li>
            <li>
              <h5>참가 선수 공지</h5>
              <div class="group">
                <strong>각 주 차별</strong>
                <p>금요일 14:00</p>
              </div>
            </li>
            <li>
              <h5>대회 당일</h5>
              <div class="group">
                <ul>
                  <li><b>체크인</b> 12:00~12:50</li>
                  <li><b>대진표 공개</b> 13:50</li>
                  <li><b>대회 진행</b> 14:00~</li>
                </ul>
              </div>
            </li>
          </ul>
        </PItem>

      </article>

      <ArticleDivider />

      <article class="arena-today">
        <PItem class="_title-sub">
          <SectionTitleSub title="대회일" theme="green" />
        </PItem>
        <PItem class="box2 content _content">
          <div class="inner">
            <h5>대회 당일 시작 시간 <em>14:00</em></h5>
            <p class="date">1/29 (토) ㅣ 2/19 (토) ㅣ 2/26 (토) ㅣ 3/12 (토) ㅣ 3/19 (토)</p>
            <ul>
              <li>
                <h6>1 ROUND</h6>
                <p><b>32 그룹</b> 1그룹 당 8명</p>
              </li>
              <li>
                <h6>2 ROUND</h6>
                <p><b>16 그룹</b></p>
              </li>
              <li>
                <h6>3 ROUND</h6>
                <p><b>8 그룹</b></p>
              </li>
              <li>
                <h6>4 ROUND</h6>
                <p><b>4 그룹</b></p>
              </li>
              <li>
                <h6>5 ROUND</h6>
                <p><b>2 그룹</b></p>
              </li>
              <li>
                <h6>6 ROUND</h6>
                <p><b>1 그룹</b> 최후 8인</p>
              </li>
            </ul>
            <p class="msg">1시간 단위로 1라운드 씩 진행</p>
          </div>
        </PItem>
      </article>

      <ArticleDivider />

      <PItem tahg="article" class="arena-apply _content">
        <div class="inner">
          <p>WEEKLY CUP을 통해 <b>레전드 컵 시드권</b>과 <b>특별 보상</b>을 획득해보세요!</p>
          <BtnGoApply event-name="landing_challenge_02" />
        </div>
      </PItem>

      <CharacterMatch />
    </div>
  </PSection>
</template>

<script>
import SectionTitle from '@/views/components/landing/tftWeeklyCup/SectionTitle.vue';
import SectionTitleSub from '@/views/components/landing/tftWeeklyCup/SectionTitleSub.vue';
import ArticleDivider from '@/views/components/landing/tftWeeklyCup/ArticleDivider.vue';
import CharacterMatch from '@/views/components/landing/tftWeeklyCup/CharacterMatch.vue';
import BtnGoApply from '@/views/components/landing/tftWeeklyCup/BtnGoApply.vue';

export default {
  name: 'SectionMatch',
  components: { SectionTitle, SectionTitleSub, ArticleDivider, CharacterMatch, BtnGoApply },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

@duration: .35s;
@ease: ease;
[tft-section-match] { .cover('@{tft-intro}/tl/bg_match.jpg'); .bg-xc; .no-repeat; .c(#fff);
  ._title {
    [tft-section-title] { clip-path: polygon(40% 0, 60% 0, 60% 100%, 40% 100%); transition: clip-path @duration @ease;
      span { .c(transparent); transition: color @duration @ease;
        &:before { .l(50%); transition: left @duration @ease; }
        &:after { .r(50%); transition: right @duration @ease; }
      }
    }
    .intro-up(0, {
      [tft-section-title] { clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        span { .c(#221968);
          &:before { .l(100%); }
          &:after { .r(100%); }
        }
      }
    });
  }
  ._title-sub, ._content, ._content-item { .ani-base;
    .intro-up(1, { .ani-on; });
  }
  ._title-sub { .rel; .z(2); }

  .inner-holder { .max-w(375); .pt(40); .pb(56); .mh-c; .tc;
    [tft-section-title-sub] { .w(256); }
  }
  .arena-guide { .max-w(348); .mt(32); .mh-c;
    article { .rel; .ls(-0.05em); .pb(6);
      &:after { .cnt; .abs; .l(26); .r(26); .b(0); border-style: solid; border-color: transparent;
        border-top-color: #084255;
        border-width: 6px 6px 0 6px;
      }
      h5 { .rel; .fs(16, 16); .tc;
        span { .rel; .ib; .hlh(26); .z(1); }
        &:before { .cnt; .abs; .l(0); .r(0); .b(0); .t(0);
          background-image: linear-gradient(360deg, #00b5aa 0%, #007792 100%);
          clip-path: path('M126.5 0L102.5 24H0.5V26H348.5V24H246.5L222.5 0H126.5Z');
        }
      }
      .group { .pt(24); .pb(12); .bgc(#084255);
        figure { .w(324); .mh-c;
          img { .wh(100%, auto); }
        }
        > p { .mt(12); .fs(14, 17);
          b { .bold; .c(#78edf9); }
          span { .block; .tc; }
        }
        > .box { .w(320); .mh-c; .p(2);
          background-image: linear-gradient(180deg, #ecc27d 0%, #945527 105.26%);
          .inner { .pv(17); .bgc(#00222d);
            clip-path: polygon(0 0, 100% 0, 100% 84%, 96% 100%, 0 100%, 0% 50%);
          }
          p { .fs(18, 18);
            b { .c(#d4ff60); }
            span { .block; .mt(4); }
          }
          + p { .font-spoqa; .fs(12, 18);
            b { .block; .c(#3bf0fc); }
          }
        }
      }
      + article { .mt(24); }
    }
  }
  .box2 { .rel; .mt(-22); .bgc(#f1f5ed); .-t(#e1e6dd, 2); .-b(#e1e6dd, 2);
    &:before,
    &:after { .cnt; .abs; border-style: solid; border-width: 12px; border-color: transparent;}
    &:before { .lt(0, -2); .w(36); border-left: 0;border-bottom: 0; border-top-color: #e1e6dd;}
    &:after { .rb(0, -2); .w(82); border-right: 0;border-top: 0;border-bottom-color: #e1e6dd;}
  }
  .arena-step {
    [tft-section-title-sub] { .rel; .z(1); }
    .content { .rel; .wh(375, 478); .pt(46); .c(#0c444d); .bgd(#f1f5ed url('@{tft-intro}/bg_arena_step.png') 6px 46px no-repeat);
      > ul {
        > li { .abs;
          &:nth-child(1) { .lt(24, 46); .w(130);
            strong { .mt(4); }
          }
          &:nth-child(2) { .lt(182, 46); .w(170); }
          &:nth-child(3) { .lt(24, 256); .w(130);
            h5 { .fs(16); }
            strong { .fs(20, 20); }
            p { .mt(4); .fs(14, 21); }
          }
          &:nth-child(4) { .lt(182, 256); .w(170);
            .group {
              li b { .w(70); .hlh(20); .bgc(#ce00d2); .br(20); }
              li + li { .mt(13); }
            }
          }
        }
      }
    }
    h5 { .bold; .h(32); .fs(18, 32); .c(#fff); .ls(-0.05em); .tc; }
    .group { .h(150); .flex; .flex-ai; .flex-jc; flex-direction: column;
      p { .font-spoqa; .bold; .fs(13, 21); .ls(-0.03em); }
      strong { .fs(16, 16); .c(#01bdd6); .ls(-0.05em); }
      ul {}
      li {.flex; .flex-ai; .font-spoqa; .bold; .fs(12, 18); .ls(-0.03em);
        b { .ib; .wh(46, 18); .mr(6); .fs(12, 18); .c(#fff); .vam; .tc; .bgc(#01bdd6); .br(10); }
        + li { .mt(8); }
      }
    }
  }
  .arena-today {
    [tft-section-title-sub] { .rel; .z(1); }
    .content { .p(44, 13, 30, 14); }
    .inner { .rel; .h(494); .bgc(#084255);
      &:after { .cnt; .abs; .rb(0, 0); .wh(0);
        border-style: solid;
        border-width: 0 0 20px 20px;
        border-color: transparent transparent #f1f5ed transparent;
      }
      //clip-path: polygon(0 0, 100% 0, 100% 95%, 95% 100%, 0 100%, 0% 50%);
    }
    h5 { .regular; .hlh(32);.fs(16); .ls(-0.03em);
      background-image: linear-gradient(270deg, #ce00d2 11.55%, #8000ff 92.98%);
      clip-path: path('M81 32L53 4H0V0H348V4H295L267 32H81Z');
      em { .bold; }
    }
    .date { .mt(18); .fs(12, 12); .ls(-0.05em); }
    ul { .rel; .h(336); .mt(24);
      &:before { .cnt; .abs; .l(-14); .r(-13); .t(0); .b(0); .no-repeat('@{tft-intro}/bg_arena_today.png'); .bg-xy(0, 0); }
    }
    li { .abs; .w(108); .ls(-0.03em);
      h6 { .hlh(32); .fs(15); .tc; }
      p { .flex-center; flex-direction: column; .h(110); .mt(14); .font-spoqa; .fs(12, 18); .c(#570058); .ls(-0.03em);
        b { .mb(3); .font-g; .fs(22, 22); .c(#6901fb); }
      }
      &:nth-child(1) { .lt(4, 0);
        b { .c(#4d01fe); }
      }
      &:nth-child(2) { .lt(120, 0);
        b { .c(#6901fb); }
      }
      &:nth-child(3) { .lt(236, 0);
        b { .c(#8501f8); }
      }
      &:nth-child(4) { .lt(4, 180);
        b { .c(#a101f5); }
      }
      &:nth-child(5) { .lt(120, 180);
        b { .c(#bd01f2); }
      }
      &:nth-child(6) { .lt(236, 180);
        b { .c(#d901ef); }
      }
    }
    .msg { .abs; .l(17); .r(17); .b(26); .hlh(30); .fs(14); .c(#d4ff60); .ls(-0.03em); .bgc(#00222d); }
  }
  .arena-apply { .rel; .wh(375, 192); .mh-c;
    .inner { .abs; .lt(50%, 0); .t-xc; .wh(437, 100%); .pt(38); .no-repeat('@{tft-intro}/bg_arena_apply.svg'); .bg-xy(0, 0); }
    p { .max-w(323); .mh-c; .regular; .fs(18, 24); .ls(-0.05em);
      b { .bold; }
    }
    [btn-go-apply] { .mt(8); }
  }
  @media (@tl-up) {
    .inner-holder { .rel; .max-w(940); .mh-c; .pt(96); .pb(104);
      [tft-section-title-sub] { .w(406); }
    }
    .arena-guide { .max-w(940); .mt(80);
      article { .w(880); .mh-c; .pb(16);
        &:after { .l(68); .r(68);
          border-width: 16px 16px 0 16px;
        }
        h5 { .fs(24, 24);
          span { .hlh(44); }
          &:before { .cnt; .abs; .l(0); .r(0); .b(0); .t(0);
            clip-path: path('M340 0L300 40H0V44H880V40H580L540 0H340Z');
          }
        }
        .group { .pt(56); .pb(20);
          figure { .wh(624, 150); }
          > p { .mt(40); .fs(24, 24);
            b { .c(#78edf9); }
            span { .ib; .vat; }
          }
          > .box { .w(594); .mh-c; .p(2);
            .inner { .pv(31);
              clip-path: polygon(0 0, 100% 0, 100% 84%, 96% 100%, 0 100%, 0% 50%);
            }
            p { .fs(30, 30);
              b { .c(#d4ff60); }
              span { .mt(10); }
            }
            + p { .fs(16, 24); }
          }
        }
        + article { .mt(60); }
      }
    }
    .box2 { .mt(-24); border-top-width: 4px; border-bottom-width: 4px;
      &:before,
      &:after { border-width: 20px; .w(138); }
      &:before { .t(-4); }
      &:after { .b(-4); }
    }
    .arena-step {
      .content { .wh(940, 388); .mh-c; .pt(0); background-image: none;
        > ul {
          &:before { .cnt; .abs; .l(-12); .r(-18); .t(0); .b(0); .bgd(url('@{tft-intro}/tl/bg_arena_step.png') 0 60px no-repeat); }
          > li {
            &:nth-child(1) { .lt(-12, 60); .w(188);
              strong { .mt(8);
                + strong { .mt(0); }
              }
            }
            &:nth-child(2) { .lt(220, 60); .w(228); }
            &:nth-child(3) { .lt(492, 60); .w(188);
              h5 { .fs(22); }
              strong { .fs(24, 24); }
              p { .mt(8); .fs(18, 27); }
            }
            &:nth-child(4) { .lt(724, 60); .w(228);
              .group {
                li b { .w(86); .hlh(24); }
                li + li { .mt(24); }
              }
            }
          }
        }
      }
      h5 { .bold; .h(44); .fs(22, 44); }
      .group { .h(224);
        p { .fs(18, 27); }
        strong { .fs(24, 32); }
        li { .fs(16, 24);
          b { .wh(58, 24); .mr(8); .fs(14, 24); .br(20); }
          + li { .mt(12); }
        }
      }
    }
    .arena-today {
      .content { .p(72, 58, 62, 58); }
      .inner { .h(710);
        &:after { border-width: 0 0 28px 28px; }
      }
      h5 { .hlh(50); .fs(24); clip-path: path('M268 50L224 6H0V0H824V6H600L556 50H268Z'); }
      .date { .mt(28); .fs(20, 20); .ls(-0.03em); }
      ul { .h(488); .mt(30); .mh(70);
        &:before { .l(0); .r(0); .no-repeat('@{tft-intro}/tl/bg_arena_today.png'); .bg-xy(0, 0); }
      }
      li { .w(164); .ls(-0.05em);
        h6 { .hlh(48); .fs(22); }
        p { .h(152); .mt(26); .bold; .fs(16, 24);
          b { .mb(2); .fs(28, 28); }
        }
        &:nth-child(1) { .lt(84, 0); }
        &:nth-child(2) { .lt(260, 0); }
        &:nth-child(3) { .lt(436, 0); }
        &:nth-child(4) { .lt(84, 264); }
        &:nth-child(5) { .lt(260, 264); }
        &:nth-child(6) { .lt(436, 264); }
      }
      .msg { .l(0); .r(0); .b(32); .w(454); .mh-c; .hlh(38); .fs(20); }
    }
    .arena-apply { .wh(864, 248);
      .inner { .wh(inherit, inherit); .pt(43); .no-repeat('@{tft-intro}/tl/bg_arena_apply.png'); }
      p { .max-w(431); .mh-c; .regular; .fs(24, 32); }
      [btn-go-apply] { .mt(9); }
    }
  }
  @media (@ds-up) {
    .inner-holder { .max-w(1260); }
    .box2 { .mt(-24); border-top-width: 4px; border-bottom-width: 4px;
      &:before,
      &:after { border-width: 20px; .w(230); }
      &:before { .t(-4); }
      &:after { .b(-4); }
    }
    .arena-step {
      .content { .wh(1260, 420);
        > ul:before { .l(0); .r(0); .bgd(url('@{tft-intro}/ds/bg_arena_step.png') 57px 80px no-repeat); }
        > ul > li {
          &:nth-child(1) { .lt(57, 80); .w(246);}
          &:nth-child(2) { .lt(357, 80); .w(246);}
          &:nth-child(3) { .lt(657, 80); .w(246);}
          &:nth-child(4) { .lt(957, 80); .w(246);}
        }
      }
    }
    .arena-today {
      .content { .p(72, 40, 62, 40); }
      .inner { .h(454);
        &:after { border-width: 0 0 40px 40px; }
      }
      h5 { clip-path: path('M456 50L412 6H0V0H1200V6H788L744 50H456Z'); }
      ul { .wh(1126, 224); .mt(38); .mh(auto);
        &:before { .l(0); .r(0); .no-repeat('@{tft-intro}/ds/bg_arena_today.png'); .bg-xy(0, 0); }
      }
      li {
        &:nth-child(1) { .lt(34, 0); }
        &:nth-child(2) { .lt(210, 0); }
        &:nth-child(3) { .lt(386, 0); }
        &:nth-child(4) { .lt(562, 0); }
        &:nth-child(5) { .lt(738, 0); }
        &:nth-child(6) { .lt(914, 0); }
      }
    }
  }
}
</style>
