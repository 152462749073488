<template>
  <PSection tft-section-key-visual>
    <div class="inner-holder">
      <div class="header">
        <h2>총 상금 600만원과 레전드 컵 시드권을 획득할</h2>
        <h1>WEEKLY CUP에 도전하세요!</h1>
        <SectionKeyVisualLogo />
      </div>
      <dl class="info">
        <dt>SET 6.5 WEEKLY CUP #1~#5 대회일</dt>
        <dd>1/29 (토) ㅣ 2/19 (토) ㅣ 2/26 (토) ㅣ 3/12 (토) ㅣ 3/19 (토)</dd>
      </dl>

      <BtnGoApply event-name="landing_challenge_01" />
    </div>

    <div class="btn-down">
      <button @click="toNextSection">Scroll Down
        <svg-angle />
      </button>
    </div>
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import SvgAngle from 'shared/graphics/svg-angle.vue';
import SectionKeyVisualLogo from '@/views/components/landing/tftWeeklyCup/SectionKeyVisualLogo.vue';
import BtnGoApply from '@/views/components/landing/tftWeeklyCup/BtnGoApply.vue';

export default {
  name: 'SectionKeyVisual',
  components: { SectionKeyVisualLogo, SvgAngle, BtnGoApply },
  mixins: [gtag],
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    toNextSection() {
      const secondSection = this.$el.nextSibling;
      const targetScrollOffset = secondSection.offsetTop - (document.querySelector('header').clientHeight || 0);
      this.$scroll.scrollMove(targetScrollOffset, 1000);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[tft-section-key-visual] { .rel; .flex-center; .min-h(567); .h(calc(var(--innerHeight) - 100px)); .tc; .c(#fff); .bgc(#090c2c); .cover('@{tft-intro}/bg_keyvisual.jpg'); .bg-xc; .crop;
  .intro-up(1, {
    [tft-section-key-visual-logo] {
      &.init { .visible;
        .inner {
          .bg-gear { .o(1); filter: blur(0); .t-s(1); }
          .text-main { .o(1); .t-s(1); }
          .tft-logo { clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }
          .text-sub { .o(1); .t-s(1); filter: blur(0); transform: scale(1); }
          .lightning { .o(1); }
        }
      }
    }
  });
  .outro-up(7, {
    [tft-section-key-visual-logo] {
      &.init { .hidden;
        .inner {
          .bg-gear s,
          .text-main,
          .lightning s { animation-play-state: paused !important;}
        }
      }
    }
  });

  .intro-up(1, {
    &:before { .t-s(1.04); }
    .header {
      h2, h1 { .o(1); .t-y(0); filter: blur(0); .t-s(1); }
    }
    dl, [btn-go-apply] { .o(1); .t-y(0); }
    .btn-down { .o(1); }
  });
  &:before { .cnt; .abs; .trbl; .cover('@{tft-intro}/bg_keyvisual.jpg'); .bg-xc; .t-s(1); transition: transform 10s ease, filter 2s cubic-bezier(1, 0, 0, 1); }
  .inner-holder { .rel; .z(2); }
  .header {
    h2, h1 { .o(0); .t-y(35); .t-s(1.25); filter: blur(3px); transition: opacity .75s .75s ease, transform .75s .75s ease, filter .75s .75s ease; }
    h2 { .regular; .fs(18, 22); }
    h1 { .mt(4); .fs(26, 110%); }
  }
  dl { .w(335); .mt(2); .mh-c; .pv(15); .ls(-0.05em); .bgc(rgba(0, 0, 0, .5)); .o(0); .t-y(75); .tr-to(.75s, .75s, .85s);
    border: 1px solid;
    border-image-source: linear-gradient(180deg, rgba(244, 227, 200, 0.5) 0%, rgba(189, 151, 123, 0.5) 125%);
    border-image-slice: 1;
    dt { .bold; .fs(14, 14); .c(#d4ff60); }
    dd { .mt(8); .regular; .fs(12, 12); .c(#fff);}
  }
  [btn-go-apply] { .mt(14); .o(0); .t-y(75); .tr-to(.75s, .75s, .95s); }
  .btn-down { animation: wr-bounce 1.5s infinite; .o(0); .abs; .z(1); .b(24); .tc; .w(100%); .t-y(30); .tr-to(.3s, .3s, 1.2s);
    > button { .ib; .c(#fff); .fs(12, 16); .ls(0);
      > svg { .wh(26, 15); .stroke(#fff); .block; .mh-c; .mt(4);}
    }
  }

  @media (@tl-up) {
    .mt(0); .h(960); .bg-s(auto, auto); .bg('@{tft-intro}/tl/bg_keyvisual.jpg');
    &:before { .bg-s(auto, auto); .bg('@{tft-intro}/tl/bg_keyvisual.jpg'); }
    .inner-holder {}
    .header {
      h2 { .fs(24, 24); }
      h1 { .mt(10); .fs(40, 100%); }
    }
    dl { .w(440); .mt(-8); .pt(24); .pb(22);
      dt { .fs(18, 18); }
      dd { .mt(12); .fs(16, 16); }
    }
    [btn-go-apply] { .mt(28); }
    .btn-down { .b(42);
      > button { .fs(16, 16);
        > svg { .wh(32, 18); .stroke(#fff); .block; .mh-c; .mt(4);}
      }
    }
  }
  @media (@ds-up) {
    .inner-holder {}
    .btn-down { .b(60); }
  }
}
</style>
