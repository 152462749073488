<template>
  <h3 tft-section-title :class="theme">
    <span>
      <slot>{{ title }}</slot>
    </span>
  </h3>
</template>

<script>
export default {
  name: 'TftSectionTitle',
  props: ['title', 'theme'],
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[tft-section-title] { .c(#fff); .fs(26, 30); .ls(-0.05em); .tc;
  > span { .rel; .ib;
    &:before,
    &:after { .cnt; .abs; .t(50%); .wh(56, 24); .no-repeat('@{tft-intro}/icon_title_deco_r.svg'); .bg-s(100%, auto); .t-yc;}
    &:before { .l(100%); .ml(4); }
    &:after { .r(100%); .mr(4); .bg('@{tft-intro}/icon_title_deco_l.svg'); }
    > span { .block; .tc; }
  }
  &.blue-black { .c(#221968);
    > span {
      &:before { .ml(8); .bg('@{tft-intro}/icon_title_deco_r_blue.svg'); }
      &:after { .mr(8); .bg('@{tft-intro}/icon_title_deco_l_blue.svg'); }
    }
  }
  @media (@tl-up) {
    .fs(46, 46);
    > span {
      &:before,
      &:after { .wh(96, 40); }
      &:before { .ml(20); }
      &:after { .mr(20); }
      > span { .ib; .vam; }
    }
  }
  @media (@ds-up) {
  }
}
</style>
