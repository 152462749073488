<template>
  <figure tft-section-key-visual-logo :class="{ animation, init }">
    <div class="inner">
      <span class="bg-gear"><s /></span>
      <span class="tft-logo"></span>
      <span class="text-main"></span>
      <span class="text-sub"><s /></span>
      <span class="lightning"><s /><s /><s /></span>
    </div>
  </figure>
</template>
<script>
export default {
  name: 'SectionKeyVisualLogo',
  data() {
    return {
      init: false,
      animation: false,
      aniStartDuration: 5000,
      aniStopDuration: 750,
      timerID: null,
    };
  },
  mounted() {
    this.aniDelay().then(() => {
      this.init = true;
      this.loopLightning();
    });
  },
  methods: {
    playAni() {
      this.animation = true;
    },
    stopAni() {
      this.animation = false;
    },
    loopLightning() {
      setInterval(() => {
        this.playAni();
        this.aniDelay(this.aniStopDuration).then(() => this.stopAni());
      }, this.aniStartDuration);
    },
    aniDelay(delay = 550) {
      clearTimeout(this.timerID);
      return new Promise(resolve => {
        this.timerID = setTimeout(() => resolve(), delay);
      });
    },
  },
};
</script>

<style lang="less">
@keyframes grarRorate {
  100% { transform: rotate(360deg); }
}
@keyframes mainGrayscale {
  0% { filter: grayscale(0) blur(0); }
  10%, 20% { filter: grayscale(25%) blur(0px); }
  30%, 50%, 70%, 90% { filter: grayscale(50%) blur(1px); }
  40%, 60%, 80% { filter: grayscale(25%) blur(0px); }
  100% { filter: grayscale(0) blur(0); }
}
@keyframes lightningGrayscale {
  0% { filter: grayscale(0) blur(0); opacity: 0; }
  10%, 20% { filter: grayscale(35%) blur(1px); opacity: .9; }
  30%, 50%, 70%, 90% { filter: grayscale(70%) blur(2px); opacity: .3; }
  40%, 60%, 80% { filter: grayscale(35%) blur(1px); opacity: .9; }
  100% { filter: grayscale(0) blur(0); opacity: 0;}
}
@import '~@/less/proj.less';
[tft-section-key-visual-logo] { .rel; .wh(325, 250); .mh-c; pointer-events: none; .hidden;
  .inner { .abs; .lt(50%, 50%); .t-xyc; .wh(325, 325);
    span,
    s { .abs; .trbl; .bg-s(100%, 100%); will-change: transform, opacity; }
    .bg-gear { transform: scale(2); filter: blur(3px); .o(.85);
      s { .bg('@{tft-intro}/tft-logo/4_bottom.png'); }
    }
    .tft-logo { .bg('@{tft-intro}/tft-logo/1_logo.png'); clip-path: polygon(50% 0, 50% 0, 50% 100%, 50% 100%); }
    .text-main { .bg('@{tft-intro}/tft-logo/2_MainText.png'); .t-s(3); .o(0); }
    .text-sub { .bg('@{tft-intro}/tft-logo/3_SubText.png'); .t-s(3); .o(0); filter: blur(3px); }
    .lightning { .o(0);
      s {
        &:nth-child(1) { .bg('@{tft-intro}/tft-logo/0_lightning_1.png'); }
        &:nth-child(2) { .bg('@{tft-intro}/tft-logo/0_lightning_2.png'); }
        &:nth-child(3) { .bg('@{tft-intro}/tft-logo/0_lightning_3.png'); }
      }
    }
  }
  &.init { .visible;
    .bg-gear {
      transition: transform .35s cubic-bezier(0.000, 1.115, 0.325, 1.200), filter .35s ease, opacity .35s ease;
      s { animation: grarRorate 20s linear backwards infinite; }
    }
    .tft-logo { transition: clip-path .75s .55s ease-out; }
    .text-main {
      animation: mainGrayscale 1s 1s linear backwards;
      transition: transform .35s .25s cubic-bezier(0.000, 1.115, 0.325, 1.200), opacity .35s .3s ease;
    }
    .text-sub { transition: transform .35s .3s cubic-bezier(0.000, 1.115, 0.325, 1.200), filter .35s .4s ease, opacity .35s .4s ease; }
    .lightning { transition: opacity .35s .55s ease-out, transform .35s .65s ease-out;
      s { animation: lightningGrayscale 1s .5s linear backwards;
        &:nth-child(2) { animation-delay: .25s; }
        &:nth-child(3) { animation-delay: .35s; }
      }
    }
  }
  &.animation {
    .text-main,
    .lightning s { animation-iteration-count: infinite; animation-delay: 0s; }
  }
  @media (@tl-up) {
    .wh(560, 430); .mt(-23);
    .inner { .wh(528, 528) ;}
  }
  @media (@ds-up) {
  }
}
</style>
