<template>
  <PSection tft-section-rules>
    <div class="holder rules">
      <h4>참여 시 꼭 확인하세요.</h4>
      <div class="rules-content">
        <article>
          <h5>대회 일정 참고사항</h5>
          <ul>
            <li>참가 접수 시작: 1월 17일(월) 11:00</li>
            <li>체크인: 대회일 12:00 ~ 12:50 진행 (50분 간)</li>
            <li>대진표 공개: 대회 당일 13:50 공개 예정</li>
            <li>대회 진행: 14:00 ~ (약 6시간 이상 소요 예정)</li>
            <li>
              래더 스냅샷: 정해진 시간에 참가 신청한 참가자들의 티어를 확인하는 시스템
              <span>(래더 스냅샷 기준 순위가 높은 유저부터 대회에 우선 참가권 부여)</span>
            </li>
          </ul>
          <h6>WEEKLY CUP #1</h6>
          <ul>
            <li>참가 접수 마감: 1월 27일(목) 18:00</li>
            <li>래더 스냅샷 확인: 1월 28일(금) 00:00</li>
            <li>참가 선수 공지: 1월 28일(금) 14:00</li>
            <li>대회 시작: 1월 29일(토) 14:00~</li>
          </ul>
          <h6>WEEKLY CUP #2</h6>
          <ul>
            <li>참가 접수 마감: 2월 17일(목) 18:00</li>
            <li>래더 스냅샷 확인: 2월 18일(금) 00:00</li>
            <li>참가 선수 공지: 2월 18일(금) 14:00</li>
            <li>대회 시작: 2월 19일(토) 14:00~</li>
          </ul>
          <h6>WEEKLY CUP #3</h6>
          <ul>
            <li>참가 접수 마감: 2월 24일(목) 18:00</li>
            <li>래더 스냅샷 확인: 2월 25일(금) 00:00</li>
            <li>참가 선수 공지: 2월 25일(금) 14:00</li>
            <li>대회 시작: 2월 26일(토) 14:00~</li>
          </ul>
          <h6>WEEKLY CUP #4</h6>
          <ul>
            <li>참가 접수 마감: 3월 10일(목) 18:00</li>
            <li>래더 스냅샷 확인: 3월 11일(금) 00:00</li>
            <li>참가 선수 공지: 3월 11일(금) 14:00</li>
            <li>대회 시작: 3월 12일(토) 14:00~</li>
          </ul>
          <h6>WEEKLY CUP #5</h6>
          <ul>
            <li>참가 접수 마감: 3월 17일(목) 18:00</li>
            <li>래더 스냅샷 확인: 3월 18일(금) 00:00</li>
            <li>참가 선수 공지: 3월 18일(금) 14:00</li>
            <li>대회 시작: 3월 19일(토) 14:00~</li>
          </ul>
        </article>
        <article>
          <h5>경기 진행 시 참고사항</h5>
          <ul>
            <li>경기 장소: 온라인</li>
            <li>
              대회 진행 안내: TFT 아마추어 리그 공식 디스코드 (모든 선수 참가 필수)<br>
              <span>*(<a href="https://discord.gg/PeV4JZSnxZ" target="_blank">https://discord.gg/PeV4JZSnxZ</a>)</span>
            </li>
            <li>경기 방식:
              <ol>
                <li><em>1.</em> 8인 프리포올</li>
                <li><em>2.</em> 6라운드 진행</li>
                <li><em>3.</em> 매 라운드는 단판으로 진행되며 그룹 별 상위 4명이 다음 라운드 진출</li>
              </ol>
            </li>
            <li>경기 진행:
              <ol>
                <li><em>1.</em> 대진표의 본인 그룹 확인</li>
                <li><em>2.</em> 디스코드 공지를 확인하여 그룹 별 대표가 본인 그룹 선수들 초대</li>
                <li><em>3.</em> 모든 선수가 입장하였을 경우 진행 (초대를 받지 않는 유저가 있을 경우 운영진에게 연락)</li>
              </ol>
            </li>
            <li>결과 입력: 각 그룹 1위가 디스코드 내 본인 그룹에 결과 입력</li>
          </ul>
        </article>
        <article>
          <h5>보상 및 혜택 주의사항</h5>
          <ul>
            <li>상금: 제세공과금은 수상자 부담</li>
            <li>TFT 랜덤 전설이 알 + LoL 명품 상자 + 열쇠: 대회 종료 후 레벨업지지 쪽지를 통하여 발송 예정</li>
            <li>
              레전드 컵 시드권: 5주차 경기 종료 후 가장 높은 위클리 포인트를 획득한 유저에게 지급<br>
              <span>* 위클리 포인트 동률 시 최근 라운드의 순위 기준으로 산정</span>
            </li>
          </ul>
        </article>
      </div>
    </div>
  </PSection>
</template>

<script>
export default {
  name: 'SectionRules',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
.font-spoqa-neo() { font-family: 'Spoqa Han Sans Neo', sans-serif; }
[tft-section-rules] { .bgc(#eae9f3);
  .rules { .max-w(313); .mh-c; .pt(60); .pb(120);
    h4 { .pb(40); .font-g; .bold; .fs(20, 20); .ls(-1); .c(rgba(34, 25, 104, .8)); .tc; }
    .rules-content { .p(20, 0, 20, 0); .-t(rgba(34, 25, 104, .2), 1); .-b(rgba(34, 25, 104, .2), 1); }
    article {
      > h5, > h6 { .font-spoqa-neo; .c(rgba(34, 25, 104, .8)); }
      > h5 { .bold; .fs(16, 20);
        + ul { .mt(12);}
      }
      > h6 { .mt(17); .fs(11, 14);
        + ul { .mt(6); }
      }
      > ul { .ls(-0.03em);
        li { .font-spoqa-neo; }
        > li { .rel; .pl(7); .fs(11, 15); .c(rgba(34, 25, 104, .6));
          > span {}
          a { text-transform: none; .c(rgba(34, 25, 104, .6));
            &:hover { .underline; }
          }
          &:before { content: '- '; .abs; .lt(0, 0); }
          + li { .mt(4); }
        }
        ol { .ml(8); .mt(4);
          li { padding-left: 16px; text-indent: -16px;
            em { .mr(7); }
          }
        }
      }
      + article { .mt(31); }
    }
  }
  @media (@tl-up) {
    .rules { .max-w(908); .pt(126); .pb(200);
      h4 { .pb(40); .fs(32, 42); }
      .rules-content { .p(42, 0, 40, 62); }
      article {
        > h5 { .fs(24, 30);
          + ul { .mt(20); }
        }
        > h6 { .mt(24); .fs(16, 20); }
        > ul { .mt(12);
          > li { .pl(10); .fs(16, 21);
            > br { .hide; }
            > span { .block; }
            + li { .mt(5); }
          }
          ol { .ml(10); .mt(4); .mb(7);
            li { padding-left: 16px; text-indent: -16px;
              em { .mr(7); }
            }
          }
        }
        + article { .mt(40); }
      }
    }
  }
  @media (@ds-up) {
    .rules { .max-w(1080); }
  }
}
</style>
