<template>
  <div tft-intro>
    <SectionKeyVisual id="tft-key-visual" />
    <SectionReward id="tft-reward" />
    <SectionMatch id="tft-match" />
    <SectionRules id="tft-rules" />
    <PSection class="quick-menu-holder">
      <QuickMenu v-prx:fixed
        v-if="quickMenu.menus.length"
        :button="quickMenu.button"
        :menus="quickMenu.menus"
        @button-click="discordConnect"
        :init-page="initPage"
        :init-sub-page="initSubPage"
      />
    </PSection>
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import SectionKeyVisual from '@/views/components/landing/tftWeeklyCup/SectionKeyVisual.vue';
import SectionReward from '@/views/components/landing/tftWeeklyCup/SectionReward.vue';
import SectionMatch from '@/views/components/landing/tftWeeklyCup/SectionMatch.vue';
import SectionRules from '@/views/components/landing/tftWeeklyCup/SectionRules.vue';
import QuickMenu from '@/views/components/landing/QuickMenu.vue';
import meta from '@/data/meta';

export default {
  name: 'tftIntro',
  components: { SectionKeyVisual, SectionReward, SectionMatch, SectionRules, QuickMenu },
  mixins: [gtag],
  data() {
    return {
      quickMenu: {
        button: { img: '/img/pages/icon/discord.svg', text: '대회 문의' },
        menus: [
          { domId: 'tft-key-visual', name: '대회 소개' },
          { domId: 'tft-reward', name: '대회 보상' },
          { domId: 'tft-match', name: '대회 방식' },
          { domId: 'tft-rules', name: '참고 사항' },
        ],
      },

    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
    initPage() {
      const q = this.$route.query.page;
      return q === 'radiant' || q === 'gold' ? 3 : -1;
    },
    initSubPage() {
      const q = this.$route.query.page;
      return q === 'radiant' ? 'RADIANT' : null;
    },
  },
  methods: {
    discordConnect() {
      this.trackEvent('landing_discord_btn', 'click', `${this.upRouteGameId}`);
      window.open('https://discord.gg/PkpEJxM98K');
    },
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[tft-intro] { .font-g;
  .quick-menu-holder { .fix; .z(10); .lt(0, 0);}
  @media (@tl-up) {
  }
  @media (@ds-up) {
  }
}
</style>
