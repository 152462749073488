<template>
  <h4 tft-section-title-sub :class="theme">
    <span>{{ title }}</span>
  </h4>
</template>

<script>
export default {
  name: 'TftSectionTitleSub',
  props: ['title', 'theme'],
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[tft-section-title-sub] { .rel; .inline-flex; .flex-ai; .flex-jc; .h(48); .m(5); .c(#fff); .bold; .fs(20, 20); .ls(-0.05em); .-a(#42aeff); .tc; .nowrap; background-image: linear-gradient(180deg, #0053cf 0%, #00aadf 113.33%);
  &:before,
  &:after { .cnt; .abs; .t(-6); .h(58); .no-repeat('@{tft-intro}/bg_title_sub_line.svg'); .bg-s(auto, 100%); }
  &:before { .l(-6); .r(30); }
  &:after { .r(-6); .w(37); background-position: 100% 0; }
  > span { .rel; .ib; .vam; .ph(12);
    &:before,
    &:after { .cnt; .ib; .wh(25, 10); .no-repeat('@{tft-intro}/icon_title_sub_deco.svg'); .bg-s(100%, auto);}
    &:before { .mr(10);}
    &:after { .ml(10); .t-r(180deg); }
  }
  &.green {
    background-image: linear-gradient(180deg, #017b94 0%, #01c2bd 113.33%);
  }
  @media (@tl-up) {
    .h(56); .m(6); .fs(28, 28);
    &:before,
    &:after { .t(-7); .h(68); .bg('@{tft-intro}/tl/bg_title_sub_line.svg'); }
    &:before { .l(-7); .r(40); }
    &:after { .w(50); .r(-7); }
    > span {
      &:before,
      &:after { .wh(38, 14); }
      &:before { .mr(20); }
      &:after { .ml(20); }
    }
  }
  @media (@ds-up) {
  }
}
</style>
