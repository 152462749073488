<template>
  <div tft-character-match>
    <figure v-prx:intro="{ r: [5, 15], tx: [150, 0], ty: [250, 0], s:[.6, 1], o: [0, 1] }">
      <img src="/img/pages/tftWeeklyCup/intro/img_character_4.png" alt="">
    </figure>
    <figure v-prx:intro="{ r: [20, 25], tx: [-150, 0], o: [0, 1] }">
      <img src="/img/pages/tftWeeklyCup/intro/img_character_5.png" alt="">
    </figure>
  </div>
</template>

<script>
export default {
  name: 'CharacterMatch',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
@keyframes dodongsilTft {
  0% { transform+_: translateY(-10px) scale(1); }
  40% { transform+_: translateY(-5px) scale(.98); }
  100% { transform+_: translateY(-10px) scale(1); }
}

[tft-character-match] { .hide;
  figure,
  figure img {
    will-change: transform;
  }
  figure img { .vat; animation: dodongsilTft 2.5s 0.5s ease-in-out infinite; }

  @media (@tl-up) {
    .block;
    figure { .abs; .z(2);
      &:nth-child(1) { .rt(-120, 860); }
      &:nth-child(2) { .lt(-242, 2360); }
    }
  }

  @media (@ds-up) {
    figure {
      &:nth-child(1) { .rt(10, 854); }
      &:nth-child(2) { .lt(-100, 2182); }
    }
  }
}
</style>
