<template>
  <div tft-character-reward>
    <figure v-prx:intro="{ r: [3, 10], tx: [-150, 0], o: [0, 1] }">
      <img src="/img/pages/tftWeeklyCup/intro/img_character_1.png" alt="">
    </figure>
    <figure v-prx:intro="{ r: [8, 20], tx: [250, 0], ty:[250, 0], s:[.6, 1], o: [0, 1] }">
      <img src="/img/pages/tftWeeklyCup/intro/img_character_2.png" alt="">
    </figure>
    <figure v-prx:intro="{ r: [13, 30], tx: [-150, 0], o: [0, 1] }">
      <img src="/img/pages/tftWeeklyCup/intro/img_character_3.png" alt="">
    </figure>
  </div>
</template>

<script>
export default {
  name: 'CharacterReward',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
@keyframes dodongsilTft {
  0% { transform+_: translateY(-10px) scale(1); }
  40% { transform+_: translateY(10px) scale(.98); }
  100% { transform+_: translateY(-10px) scale(1); }
}

[tft-character-reward] { .hide;
  figure,
  figure img {
    will-change: transform;
  }
  figure img { .vat; animation: dodongsilTft 2.5s 0.5s ease-in-out infinite; }

  @media (@tl-up) {
    .block;
    figure { .abs; .z(2);
      &:nth-child(1) { .lt(-160, 162); }
      &:nth-child(2) { .rt(-194, 1306); }
      &:nth-child(3) { .lt(-254, 2328); }
    }
  }

  @media (@ds-up) {
    figure {
      &:nth-child(1) { .lt(-60, 198); }
      &:nth-child(2) { .rt(-58, 1306); }
      &:nth-child(3) { .lt(-124, 2264); }
    }
  }
}
</style>
