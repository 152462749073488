<template>
  <PSection tft-section-reward>
    <div class="inner-holder">
      <PItem class="_title">
        <SectionTitle>WEEKLY CUP만의
          <span>다양한 보상</span>
        </SectionTitle>
      </PItem>
      <article class="prize-money">
        <PItem class="_title-sub">
          <SectionTitleSub title="WEEKLY 상금" />
        </PItem>
        <PItem class="content _content">
          <div class="box rank-money">
            <h5>총 상금 <b>600만원</b></h5>
            <ol>
              <li>50<s>만원</s></li>
              <li>40<s>만원</s></li>
              <li>20<s>만원</s></li>
              <li>10<s>만원</s></li>
            </ol>
            <p class="x-week">x 5주</p>
          </div>
          <p class="msg">주 차별 순위 달성 시 상금 획득!</p>
        </PItem>
      </article>

      <ArticleDivider />

      <article class="give-point">
        <PItem class="_title-sub">
          <SectionTitleSub title="WEEKLY 포인트 부여" />
        </PItem>
        <div class="content">
          <PItem class="box rank-point _content-item">
            <ol>
              <li>10<s>점</s></li>
              <li>8<s>점</s></li>
              <li>6<s>점</s></li>
              <li>5<s>점</s></li>
            </ol>
            <p class="x-week">x 5주</p>
          </PItem>
          <PItem class="_content-item">
            <figure>
              <img src="/img/pages/tftWeeklyCup/intro/img_reward_point.png" alt="">
            </figure>
            <div class="msg">
              <p>
                <b>5주</b> 동안의 대회가 종료되고
                <span><b>가장 높은 포인트</b>를 달성한 <b>참가자 1인</b>에게</span>
                <strong>레전드 컵 시드권 지급!</strong>
              </p>
            </div>
          </PItem>
        </div>
      </article>

      <ArticleDivider />

      <article class="entry-reward">
        <PItem class="_title-sub">
          <SectionTitleSub title="WEEKLY 참가 보상" />
        </PItem>
        <PItem class="content _content">
          <p>각 주 차별 1라운드를 진행한 참가자 256명 모두에게</p>
          <h5>특별한 참가 보상 지급!</h5>
          <ul>
            <li>
              <figure>
                <img src="/img/pages/tftWeeklyCup/intro/img_entry_reward_1.png" alt="">
              </figure>
              <p>TFT 랜덤 전설이 알</p>
            </li>
            <li>
              <figure>
                <img src="/img/pages/tftWeeklyCup/intro/img_entry_reward_2.png" alt="">
              </figure>
              <p>LoL 명품 상자 · 열쇠</p>
            </li>
          </ul>
        </PItem>
      </article>

      <CharacterReward />
    </div>
  </PSection>
</template>

<script>
import SectionTitle from '@/views/components/landing/tftWeeklyCup/SectionTitle.vue';
import SectionTitleSub from '@/views/components/landing/tftWeeklyCup/SectionTitleSub.vue';
import ArticleDivider from '@/views/components/landing/tftWeeklyCup/ArticleDivider.vue';
import CharacterReward from '@/views/components/landing/tftWeeklyCup/CharacterReward.vue';

export default {
  name: 'SectionReward',
  components: { SectionTitle, SectionTitleSub, ArticleDivider, CharacterReward },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

@duration: .35s;
@ease: ease;
[tft-section-reward] { .cover('@{tft-intro}/tl/bg_reward.jpg'); .bg-xc; .c(#fff); .tc;
  ._title {
    [tft-section-title] { clip-path: polygon(40% 0, 60% 0, 60% 100%, 40% 100%); transition: clip-path @duration @ease;
      span { .c(transparent); transition: color @duration .15s @ease;
        &:before { .l(50%); transition: left @duration @ease; }
        &:after { .r(50%); transition: right @duration @ease; }
      }
    }
    .intro-up(0, {
      [tft-section-title] { clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        span { .c(#fff);
          &:before { .l(100%); }
          &:after { .r(100%); }
        }
      }
    });
  }
  ._title-sub, ._content, ._content-item { .ani-base;
    .intro-up(1, { .ani-on; });
  }
  ._title-sub { .rel; .z(2); }

  .inner-holder { .rel; .p(40, 0, 56, 0); .max-w(375); .mh-c; }
  .content { .mt(-19); .p(35, 16, 0, 16); .bgc(rgba(4, 0, 36, .5)); .tl; }
  .box { .rel; .w(342); .mh-c; .p(20, 0, 23, 0); .bgc(#eae9f3); .-a(#d0cfe2, 5); border-bottom-width: 13px;
    &:after { .cnt; .abs; .l(40px); .r(40px); .b(-8); border-style: solid; border-color: transparent;
      border-top-color: #eae9f3;
      border-width: 8px;
      border-bottom: 0;
    }
    li { .flex; .flex-ai; .bold; .fs(26, 26);
      s { .rel; .t(3); .fs(18, 18); }
      &:nth-child(1) { .c(#69b905); }
      &:nth-child(2) { .c(#00c27c); }
      &:nth-child(3) { .c(#12c7d2); }
      &:nth-child(4) { .c(#2e83eb); }
    }
    .x-week { .abs; .lb(0, 10); .r(0); .fs(16, 16); .c(#221968); .bold; .ls(-0.05em); .tc; }
  }
  .prize-money { .mt(36);
    [tft-section-title-sub] { .w(256); }
    .content { .pb(20); }
    .rank-money { .h(314); .no-repeat('@{tft-intro}/bg_reward_rank_money.svg'); .bg-xy(-5, -5);
      h5 { .flex-center; .wh(250, 44); .mh-c; .regular; .fs(22);
        b { .bold; }
      }
      li { .abs; .wh(146, 58); .pl(60);
        &:nth-child(1) { .lt(12, 102); }
        &:nth-child(2) { .lt(174, 102); }
        &:nth-child(3) { .lt(12, 176); }
        &:nth-child(4) { .lt(174, 176);}
      }
    }
    .msg { .mt(18); .bold; .fs(18, 18); .c(#d4ff60); .ls(-0.05em); .tc; }
  }
  .give-point {
    [tft-section-title-sub] { .w(324); }
    .content { .pb(29); }
    .rank-point { .h(264); .bgc(#14315c); border-color: #213f6c; .no-repeat('@{tft-intro}/bg_give_point.svg'); .bg-xy(-5, -5);
      &:after { border-top-color: #14315c;}
      li { .abs; .flex; .flex-ai(flex-end); .flex-jc; .wh(118, 58); .pb(8); .tc;
        s { .t(-1); }
        &:nth-child(1) { .lt(36, 36); }
        &:nth-child(2) { .lt(178, 36); }
        &:nth-child(3) { .lt(36, 130); }
        &:nth-child(4) { .lt(178, 130);}
      }
      .x-week { .c(#fff); }
    }
    figure { .w(298); .mt(26); .mh-c;
      img { .wh(100%, auto); }
    }
    .msg { .mt(22); .regular; .fs(14, 14); .ls(-0.05em); .tc;
      p { .rel; .ib;
        &:before,
        &:after { .cnt; .abs; .t(50%); .wh(12, 76); .no-repeat; .bg-s(100%, auto); .t-yc; }
        &:before { .l(-20); .bg('@{tft-intro}/bg_rank_point_msg_deco_l.svg'); }
        &:after { .r(-20); .bg('@{tft-intro}/bg_rank_point_msg_deco_r.svg'); }
      }
      b { .bold; }
      span { .mt(6); .fs(16, 16); .c(#d4ff60); .block; }
      strong { .bold; .mt(8); .fs(28, 28); .c(#3bf0fc); .block; }
    }
  }
  .entry-reward {
    [tft-section-title-sub] { .w(324); }
    .content { .pb(21); .ls(-0.05em); .tc;
      > p { .fs(14, 14); }
    }
    h5 { .mt(8); .fs(28, 28); .c(#d4ff60); }
    li { .mt(5);
      p { .mt(16); .fs(18); }
      + li { .mt(10); }
    }
    figure { .w(316); .mh-c;
      img { .wh(100%, auto); }
    }
  }

  @media (@tl-up) {
    .inner-holder { .max-w(940); .pt(96); .pb(104); }
    .content { .mt(-24); .p(62, 36, 0, 36); }
    .box { .rel; .w(868); .p(38, 0, 23, 0); border-width: 8px; border-bottom-width: 31px;
      &:after { .cnt; .abs; .l(186px); .r(186px); .b(-22);
        border-top-color: #eae9f3;
        border-width: 22px;
        border-bottom: 0;
      }
      li { .fs(40, 40);
        s { .rel; .t(4); .fs(30, 30); }
      }
      .x-week { .b(7); .fs(28, 28); }
    }
    .prize-money { .mt(80);
      [tft-section-title-sub] { .w(470); }
      .content { .pb(36); }
      .rank-money { .h(446); .no-repeat('@{tft-intro}/tl/bg_reward_rank_money.svg'); .bg-xy(-8, -8);
        h5 { .wh(340, 52); .fs(28, 28); }
        li { .wh(250, 82); .pl(114);
          &:nth-child(1) { .lt(241, 130); .wh(370, 82); .pl(164); .fs(50, 50);
            s { .t(7); }
          }
          &:nth-child(2) { .lt(27, 236); }
          &:nth-child(3) { .lt(301, 236); }
          &:nth-child(4) { .lt(575, 236); }
        }
      }
      .msg { .mt(32); .fs(24, 24); }
    }
    .give-point {
      [tft-section-title-sub] { .w(598); }
      .content { .pb(46); }
      .rank-point { .h(288); .no-repeat('@{tft-intro}/tl/bg_give_point.svg'); .bg-xy(-8, -8);
        li { .wh(170, 90); .pb(8); .fs(36);
          s { .t(-2); .fs(28); }
          &:nth-child(1) { .lt(41, 70); }
          &:nth-child(2) { .lt(241, 70); }
          &:nth-child(3) { .lt(441, 70); }
          &:nth-child(4) { .lt(641, 70);}
        }
      }
      figure { .w(436); .mt(40); }
      .msg { .mt(28); .fs(24, 24);
        p {
          &:before,
          &:after { .wh(20, 122); }
          &:before { .l(-60); }
          &:after { .r(-60); }
        }
        span { .mt(12); .fs(24, 24); }
        strong { .fs(42, 42); }
      }
    }
    .entry-reward {
      [tft-section-title-sub] { .w(598); }
      .content { .pb(53);
        > p { .fs(24, 24); }
      }
      h5 { .fs(42, 42); }
      ul { .flex; .flex-jc; }
      li { .mt(12);
        p { .mt(24); .fs(24); }
        + li { .mt(12); .ml(40); }
      }
      figure { .w(416); }
    }
  }

  @media (@ds-up) {
    .inner-holder { .max-w(1260); }
    article .content { .max-w(1064); .mh-c; }
    .box { .w(940); .p(38, 0, 23, 0); border-width: 8px; border-bottom-width: 31px;
      &:after { .l(210px); .r(210px); .b(-24);
        border-width: 24px;
        border-bottom: 0;
      }
    }
    .prize-money {
      .rank-money { .bg-x(36);
        li {
          &:nth-child(1) { .lt(285, 130);}
          &:nth-child(2) { .lt(71, 236); }
          &:nth-child(3) { .lt(345, 236); }
          &:nth-child(4) { .lt(619, 236); }
        }
      }
    }
    .give-point {
      .rank-point { .bg-x(36);
        li {
          &:nth-child(1) { .lt(85, 70); }
          &:nth-child(2) { .lt(285, 70); }
          &:nth-child(3) { .lt(485, 70); }
          &:nth-child(4) { .lt(685, 70);}
        }
      }
    }
  }
}
</style>
